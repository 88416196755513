import { ReactiveTraderIcon } from "./types"

export const ForbiddenIcon = ({
  width = 16,
  height = 16,
}: ReactiveTraderIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="currentColor">
      <path d="M14.9 22.75H9.10001C8.21001 22.75 7.07 22.28 6.45 21.65L2.35001 17.55C1.72001 16.92 1.25 15.78 1.25 14.9V9.10001C1.25 8.21001 1.72001 7.07001 2.35001 6.45001L6.45 2.35001C7.08 1.72001 8.22001 1.25 9.10001 1.25H14.9C15.79 1.25 16.93 1.72001 17.55 2.35001L21.65 6.45001C22.28 7.08001 22.75 8.22001 22.75 9.10001V14.9C22.75 15.79 22.28 16.93 21.65 17.55L17.55 21.65C16.92 22.28 15.79 22.75 14.9 22.75ZM9.10001 2.75C8.61001 2.75 7.84999 3.06 7.50999 3.41L3.41 7.51001C3.07 7.86001 2.75 8.61001 2.75 9.10001V14.9C2.75 15.39 3.06 16.15 3.41 16.49L7.50999 20.59C7.85999 20.93 8.61001 21.25 9.10001 21.25H14.9C15.39 21.25 16.15 20.94 16.49 20.59L20.59 16.49C20.93 16.14 21.25 15.39 21.25 14.9V9.10001C21.25 8.61001 20.94 7.85001 20.59 7.51001L16.49 3.41C16.14 3.07 15.39 2.75 14.9 2.75H9.10001Z" />
      <path d="M4.93891 19.8299C4.74891 19.8299 4.55891 19.7599 4.40891 19.6099C4.11891 19.3199 4.11891 18.8399 4.40891 18.5499L18.5489 4.40988C18.8389 4.11988 19.3189 4.11988 19.6089 4.40988C19.8989 4.69988 19.8989 5.17988 19.6089 5.46988L5.4689 19.6099C5.3189 19.7599 5.1289 19.8299 4.93891 19.8299Z" />
    </g>
  </svg>
)
